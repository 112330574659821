<template>
  <div class="shared-selection">
    <div v-if="loading" class="loading">
      <Loader :visible="true" />
    </div>

    <div v-else-if="error" class="error-message">
      {{ error }}
    </div>

    <div v-else class="selection-content">
      <div class="sharingBanner">
    <span>{{ selection?.userDisplayName + " shared with you!" }}</span>
      </div>

      <div class="grid-container">
        <!-- BOARD A -->
        <div class="grid-item column-1">
          <h4 class="column-title">FURNITURE BOARD A</h4>
          <RoomBuilderThumbnails
            v-if="selection != null"
            class="thumbnails-1"
            :products="selection.boards[0].furniture"
            :isMobile="isMobile"
            :readonly="true"
            @click-thumbnail="handleThumbnailClick"
          />
        </div>

        <div class="column-divider"></div>

        <!-- BOARD B -->
        <div class="grid-item column-2">
          <h4 class="column-title">FURNITURE BOARD B</h4>
          <RoomBuilderThumbnails
            v-if="selection != null"
            class="thumbnails-2"
            :products="selection.boards[1].furniture"
            :isMobile="isMobile"
            :readonly="true"
            @click-thumbnail="handleThumbnailClick"
          />
        </div>
      </div>

      <!-- Furniture Detail Modal -->
      <transition name="fade">
        <FurnitureDetail
          v-if="selectedFurniture"
          class="furniture-detail"
          :product="selectedFurniture"
          @FURNITURE_DETAIL_CLOSE="selectedFurniture = null"
          :readonly="true"
        />
      </transition>
    </div>
  </div>
</template>

<script>
import { getFunctions, httpsCallable } from 'firebase/functions'
import RoomBuilderThumbnails from '@/components/room-builder/room-builder-thumbnails.vue'
import FurnitureDetail from '@/components/room-builder/furniture-detail.vue'
import Loader from '@/components/Loader'

export default {
  name: 'SharedSelection',

  components: {
    RoomBuilderThumbnails,
    FurnitureDetail,
    Loader
  },

  data () {
    return {
      loading: true,
      error: null,
      selection: null,
      isMobile: false,
      selectedFurniture: null
    }
  },

  async created () {
    try {
      const functions = getFunctions()
      const getSelection = httpsCallable(functions, 'getSelectionsByShareToken')

      const result = await getSelection({
        token: this.$route.params.token
      })

      this.selection = result.data
    } catch (error) {
      console.error('Error loading shared selection:', error)
      this.error = 'Unable to load this selection. The link may be invalid or expired.'
    } finally {
      this.loading = false
    }
  },

  mounted () {
    window.addEventListener('resize', this.handleWindowResize)
    this.handleWindowResize()
  },

  unmounted () {
    window.removeEventListener('resize', this.handleWindowResize)
  },

  methods: {
    handleWindowResize () {
      this.isMobile = window.innerWidth <= 1024
    },

    getDataElementByID (id) {
      const result1 = this.selection.boards[0].furniture.find(item => item.recordId === id)
      const result2 = this.selection.boards[1].furniture.find(item => item.recordId === id)
      return (result1 !== undefined) ? result1 : result2
    },

    handleThumbnailClick (furnitureId) {
      const furniture = this.getDataElementByID(furnitureId)
      if (furniture) {
        this.selectedFurniture = furniture
      }
    }
  }
}
</script>

<style lang="scss">
.shared-selection {
  padding: 40px 20px;
  max-width: 1200px;
  margin: 0 auto;
  min-height: 100vh;
  position: relative;

  .selection-header {
    text-align: center;
    margin-bottom: 40px;

    h1 {
      font-family: 'Sohne';
      font-size: 38px;
      color: #1D1B19;
    }
  }

  .loading {
    text-align: center;
    padding: 100px 0;
  }

  .error-message {
    text-align: center;
    padding: 40px;
    color: #dc3545;
  }

  .grid-container {
    position: relative;
    display: grid;
    grid-template-columns: 50% 50%;
    padding: 10px;
  }

  .column-divider {
    position: absolute;
    height: calc(100% - 50px);
    bottom: 0;
    left: 50%;
    border: 1px solid #73716F;
    opacity: 0.25;
  }

  .column-title {
    margin-bottom: 20px;
    font-family: 'Sohne' !important;
    text-align: center;
  }

  .furniture-detail {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 90%;
    max-width: 800px;
    background: white;
    z-index: 1000;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    max-height: 90vh;
    overflow-y: auto;
  }

  // Animacja dla modalu
  .fade-enter-active,
  .fade-leave-active {
    transition: opacity 0.3s ease;
  }

  .fade-enter-from,
  .fade-leave-to {
    opacity: 0;
  }

  @include breakpoint($m-down) {
    .grid-container {
      display: block;
    }

    .column-divider {
      display: none;
    }

    .column-1,
    .column-2 {
      margin-bottom: 30px;
    }

    .furniture-detail {
      width: 95%;
      margin: 10px auto;
      position: fixed;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }
}

.sharingBanner{
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 25px;
  width: 100%;
  background-color: #004021;
  color: white;
  font-size: 36px;
  font-weight: 500;
  padding: 24px;
  letter-spacing: 0.5px;
}

</style>
